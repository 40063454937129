/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const SairaExtraCondensed_100Thin = require('./SairaExtraCondensed_100Thin.ttf');
export const SairaExtraCondensed_200ExtraLight = require('./SairaExtraCondensed_200ExtraLight.ttf');
export const SairaExtraCondensed_300Light = require('./SairaExtraCondensed_300Light.ttf');
export const SairaExtraCondensed_400Regular = require('./SairaExtraCondensed_400Regular.ttf');
export const SairaExtraCondensed_500Medium = require('./SairaExtraCondensed_500Medium.ttf');
export const SairaExtraCondensed_600SemiBold = require('./SairaExtraCondensed_600SemiBold.ttf');
export const SairaExtraCondensed_700Bold = require('./SairaExtraCondensed_700Bold.ttf');
export const SairaExtraCondensed_800ExtraBold = require('./SairaExtraCondensed_800ExtraBold.ttf');
export const SairaExtraCondensed_900Black = require('./SairaExtraCondensed_900Black.ttf');
